<template>
  <div>
    <!--Sliders Section-->
    <section>
      <div class="bannerimg cover-image bg-background3">
        <div class="header-text mb-0">
          <div class="container">
            <div class="text-center text-white">
              <h1 class="">S.S.S</h1>
            </div>
          </div>
        </div>
      </div>
      <Breadcrumb
        title="S.S.S"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: false, url: '', text: 'Sıkça Sorulan Sorular' },
        ]"
      />
    </section>
    <!--/Sliders Section-->

    <!--Faq section-->
    <section class="sptb">
      <div class="container">
        <div
          class="panel-group1"
          v-for="(item, i) in pageGet"
          :key="'topList' + i"
          :id="'accordion' + i"
        >
          <h2>{{ item.title }}</h2>
          <div
            class="panel panel-default mb-4 border p-0"
            v-for="(list, a) in item.sublist"
            :key="'subList' + i + '_' + a"
          >
            <div class="panel-heading1">
              <h4 class="panel-title1">
                <a
                  class="accordion-toggle collapsed"
                  data-bs-toggle="collapse"
                  :data-parent="'#accordion' + i + '_' + a"
                  :href="'#collapse' + i + '_' + a"
                  aria-expanded="false"
                  >{{ list.title }}</a
                >
              </h4>
            </div>
            <div
              :id="'collapse' + i + '_' + a"
              class="panel-collapse collapse active"
              role="tabpanel"
              aria-expanded="false"
            >
              <div class="panel-body bg-white">
                <p v-html="list.content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/Faq section-->
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {

  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "Sık Sorulan Sorular - Terapi Vitrini",
      "Sık Sorulan Sorular - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
      "",
      "",
      "",
    ]);
    this.$store.dispatch("faqGet").then((value) => {
      this.pageGet = value;
    });
  },
  data() {
    return {
      pageGet: [],
    };
  },
  components: { Breadcrumb },
  mounted() {},
};
</script>